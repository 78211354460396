<template>
  <view class="sign-custom-container">
    <view class="sign-custom-back"></view>
    <view class="sign-custom-main">
      <view class="sign-custom-title">骨转移周刊</view>
      <view class="sign-custom-title-second">会议报名及签到中心</view>
      <view class="sign-custom-content global-shadow">
        <image class="sign-doctor"  :src="require('@/assets/images/live/doctor.png')"  alt=""></image>
        <view class="sign-icon">
          <image class="sign-icon-item" :src="require('@/assets/images/live/logo.jpg')"  alt=""></image>
        </view>
        <view class="sign-form global-center">
          <view class="sign-custom-block">
            <view class="sign-custom-block-key">姓名</view>
            <view class="sign-custom-block-value">
              <input  placeholder="请输入姓名" v-model="data.name" />
            </view>
          </view>
        </view>
        <view class="sign-custom-btn-group global-center">
          <CustomButton class="sign-custom-btn" @click="doSign" round>确认</CustomButton>
        </view>
      </view>
    </view>
  </view>
</template>

<script>
import CustomButton from '@/components/unit/CustomButton.vue';
export default {
  name: "Sign",
  components:{
    CustomButton
  },
  data() {
    return {
      data: {
        name: "",
      },
      query:{}
    };
  },
  created() {
    this.query = this.$route.query
  },
  methods: {
    formatData() {
      if (!this.data.name) {
        this.$tips.error({ text: "请输入名字" });
        return false;
      }
      return true;
    },
    doSign() {
      if (!this.formatData() || this.loading) return;
      this.loading = true;
      let data = {
        ...this.data,
        ...this.$tools.getSystemInfo(),
        meet_id:this.query.id
      }
      data.height = String(data.height)
      data.width = String(data.width)
      this.$axios.post(this.$urls.live.sign, data).then((res) => {
        setTimeout(() => { this.loading = false;  }, 1000);
        this.$router.push({
          path:"/live",
          query:{id:this.query.id}
        })
      }).catch(err=>{
        setTimeout(() => { this.loading = false;  }, 1000);
      });
    },
  },
};
</script>

<style lang="scss">
.sign-custom-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;
  position: relative;
  background-image: url("../../../assets/images/bg.png");
  background-size:  100% 100%;
}
.sign-custom-content{
  // width:88%;
  // height:88%;
  background: #fff;;
  display: flex;
  flex-direction: column;
  padding:30px 15px;
  border-radius: 8px;;
  margin-top:15px;
  position: relative;
}
.sign-doctor{
  width:150px;
  height:150px;
  position: absolute;
  right:0;
  top:-90px;
}
.sign-custom-back {
  height: 100%;
  width: 100%;
  position: absolute;
  left: 0;
  top: 0;
  background: #f5f5f5;
  opacity: 0.2;
}
.sign-custom-main {
  position: relative;
  z-index: 2;
}
.sign-form{
  flex:1 0 0
}
.sign-custom-block{
  width:100%;
}
.sign-custom-block input {
  border: none;
  font-size: 15px;
  padding-left: 0;
  padding-right: 0;
}
.sign-custom-block-key {
  font-weight: 700;
  flex: 80px 0 0;
  text-align: center;
}
.sign-custom-block-value {
  flex: 1 0 0;
  border-bottom: 1px solid #f1f1f1;
}
.sign-custom-btn-group {
  width: 100%;
  margin-top: 20px;
}
.sign-custom-btn {
  width: 30vw;
}
.sign-custom-block {
  height: 90px;
  background: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
}

.sign-custom-block:last-child {
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
}
.sign-custom-title {
  font-size: 30px;
  font-weight: 700;
  height: 20px;
  display: flex;
  justify-content: center;
  align-items: flex-end;
  width: 100%;
  color:#f2674a;
}
.sign-custom-title-second {
  font-size: 18px;
  height: 145px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  color:#f2674a;
}
.sign-icon{
  padding-left:75px;
}
.sign-icon-item{
  width:12vw;
  height:12vw;
}
</style>
